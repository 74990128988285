import React from 'react';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import Contacts from '../../components/sections/contacts/Contacts';

const ContactUs: React.FC = () => (
  <LayoutDefault>
    <PageMetaData title="Contact Us" />
    <Contacts />
  </LayoutDefault>
);

export default ContactUs;
