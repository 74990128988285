/* global google */
import React, { useState } from 'react';
import {
  Map,
  Marker,
  GoogleApiWrapper,
  IMapProps,
  IMarkerProps,
  GoogleAPI,
  markerEventHandler,
  mapEventHandler,
} from 'google-maps-react';
import { Breakpoint } from '../../../../types/breakpoint';
import { getCurrentBreakpoint } from '../../../../utils/breakpoint';

interface Point {
  lat: number;
  long: number;
  icon: string;
}

interface MapProps {
  loaded: boolean;
  google: GoogleAPI;
}

const ContactsMap: React.FC<MapProps> = props => {
  const [mapElement, setMapElement] = useState<google.maps.Map | null>(null);
  const mapOptions: Omit<IMapProps, 'google'> = {
    initialCenter: {
      lat: 54.909556,
      lng: 23.93057,
    },
    zoom: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    mapTypeControl: false,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };
  const markers: IMarkerProps[] = [];
  const points: Point[] = [
    {
      lat: 54.721032,
      long: 25.281786,
      icon: require('../../../../assets/images/map/map-pin-1.svg').default,
    },
    {
      lat: 54.909556,
      long: 23.93057,
      icon: require('../../../../assets/images/map/map-pin-2.svg').default,
    },
  ];
  const isContactsOnMap = (): boolean => getCurrentBreakpoint() >= Breakpoint.lg;

  const markerClickHandler: markerEventHandler = (markerProps?, marker?): void => {
    const markerPosition = marker?.getPosition();
    if (markerPosition) {
      mapElement!.setZoom(16);
      mapElement!.setCenter(markerPosition);
    }

    if (isContactsOnMap()) {
      // If contacts block is on map then pan map to right.
      mapElement!.panBy(-200, 0);
    }
  };

  points.forEach((point: any) => {
    const iconWidth = 23;
    const iconHeight = 33;

    const marker: IMarkerProps = {
      position: {
        lat: point.lat,
        lng: point.long,
      },
      animation: google.maps.Animation.DROP,
      // Force IE11 to show svg marker icons.
      optimized: false,
      icon: {
        url: point.icon,
        size: new google.maps.Size(iconWidth, iconHeight),
        scaledSize: new google.maps.Size(iconWidth, iconHeight),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(iconWidth / 2, iconHeight / 2),
      },
      onClick: markerClickHandler,
    };

    markers.push(marker);
  });

  const centerToBounds = (bounds: google.maps.LatLngBounds, map: google.maps.Map): void => {
    map.fitBounds(bounds);
    map.panToBounds(bounds);

    if (isContactsOnMap()) {
      // If contacts block is on map then pan map to right and zoom out.
      map.panBy(-350, 0);

      google.maps.event.addListenerOnce(map, 'idle', () => {
        map.setZoom(map.getZoom() - 1);
      });
    }
  };

  const mapLoaded: mapEventHandler = (mapProps, map) => {
    setTimeout(() => {
      if (map) {
        setMapElement(map);
        const bounds = map.getBounds();
        if (bounds) {
          markers.forEach(marker => {
            bounds?.extend(new google.maps.LatLng(marker.position?.lat as number, marker.position?.lng as number));
          });

          centerToBounds(bounds, map);
        }
      }
    }, 100);
  };

  return (
    <div className="map">
      <Map google={props.google} {...mapOptions} onReady={mapLoaded}>
        {markers?.map((marker, i) => (
          <Marker {...marker} key={i} />
        ))}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
})(ContactsMap);
