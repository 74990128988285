import { Breakpoint } from '../types/breakpoint';
import { enumValues } from './enum';

const reversedBreakpointValues = enumValues(Breakpoint).reverse();

export const getWindowWidth = (): number =>
  window.innerWidth || window.document.documentElement.clientWidth || window.document.body.clientWidth;

export const getCurrentBreakpoint = (): Breakpoint => {
  const screenWidth = getWindowWidth();

  return reversedBreakpointValues.find(breakpoint => screenWidth && screenWidth >= breakpoint)!;
};
