import React from 'react';
import classNames from 'classnames';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import Map from './partials/Map';
import Image from '../../elements/Image';

interface ContactsProps extends SectionTilesTypesProps {
  className?: string;
}

const defaultProps: ContactsProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const Contacts: React.FC<ContactsProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'contact-us section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'contact-us-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const sectionHeader = {
    title: 'Contact us',
    paragraph: '',
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader title="Contact us" className="center-content pb-32">
            <p className="mb-0">Less contemplation, more action!</p>
            <p>Schedule a meeting or call immediately to address all your concerns.</p>
            <a
              href="https://calendly.com/teambit-atlassian/demo"
              target="_blank"
              className="button button-primary button-wide-mobile"
              rel="noopener noreferrer"
            >
              Book a meeting
            </a>
          </SectionHeader>
        </div>
      </div>
      <div className="map-wrapper">
        <Map />
        <div className="map-overlay">
          <div className="map-overlay-content">
            <p className="map-overlay-title">UAB TeamBit</p>

            <div className="map-item mb-16">
              <div className="map-item-icon">
                <Image src={require('../../../assets/images/contacts/pin-1.svg')} alt="" />
              </div>
              <div className="map-item-content">
                <div className="map-item-title">Vilnius office</div>
                <a
                  className="map-item-desc"
                  href="https://www.google.com/maps/place/54%C2%B043'15.7%22N+25%C2%B016'54.4%22E/@54.721032,25.2795973,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d54.721032!4d25.281786"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  J. Balcikonio str. 9, 3rd floor, 08247, Vilnius
                </a>
              </div>
            </div>

            <div className="map-item mb-16">
              <div className="map-item-icon">
                <Image src={require('../../../assets/images/contacts/pin-2.svg')} alt="" />
              </div>
              <div className="map-item-content">
                <div className="map-item-title">Kaunas office</div>
                <a
                  className="map-item-desc"
                  href="https://www.google.com/maps/place/Informacini%C5%B3+Technologij%C5%B3+Organizacija+(iTo)/@54.9095587,23.9283756,17z/data=!4m13!1m7!3m6!1s0x46e7189a0e7aa0db:0x8778bd28c5e7babd!2sSavanori%C5%B3+pr.+184,+Kaunas+50182!3b1!8m2!3d54.9095556!4d23.9305696!3m4!1s0x46e7188eee0bb677:0x7c908273d8687ec5!8m2!3d54.9095623!4d23.9306153"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Savanoriu av. 184, 44150, Kaunas
                </a>
              </div>
            </div>

            <div className="map-item mb-0 mb-md-3 ml-3 pl-3">
              <div className="map-item-title">TeamBit team contacts</div>
              <div className="map-item-desc">
                <span>Phone: </span>
                <a href="tel:+37067438112">+370 674 38112</a>
              </div>
              <div className="map-item-desc d-flex">
                <span>Emails:&nbsp;</span>
                <span>
                  <a className="text-underline" href="mailto:sales@teambit.lt">
                    sales@teambit.lt
                  </a>
                  <br/>
                  <a className="text-underline" href="mailto:info@teambit.lt">
                    info@teambit.lt
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Contacts.defaultProps = defaultProps;

export default Contacts;
